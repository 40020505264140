.RolesForm {
  max-height: 400px;
}

.CheckboxContainer,
.DescriptionsContainer {
  max-height: 280px;
  overflow-y: auto;
}

.MainMenuItem {
  padding-left: 0px;
  display: flex;
  gap: 12px;
  font-weight: bold;

  &-Child {
    padding-left: 30px;
    display: flex;
    gap: 12px;

    &-Option {
      padding-left: 60px;
      display: flex;
      gap: 12px;
    }
  }
}
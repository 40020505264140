.SuccessCard {
  display: flex;
  flex-direction: column;
  min-height: 450px;
  justify-content: center;


  &__Title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }

  p{
    font-size: 12px;
    text-align: center;
  }

  #animationSuccess{
    display: block;
    width:100px;
    height:100px;
    margin: 0px auto 30px;
    position: relative;
    .lottie{
      position: absolute;
      left:50%;
      top: 50%;
      transform: translate(-50%,-50%)
    }
  }
}

@import './PricingRuleForm';

%base-text {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: $cielo-text-extra;
}

%stats-text {
  font-weight: 400;
  font-size: 12px;
  color: #4D5168;
}

.RegionName {
  @extend %base-text;
  font-size: 18px;

  .CountryStats {
    @extend %stats-text;
  }
}

.CountryName {
  @extend %base-text;
  font-weight: 700;
  font-size: 15px;

  .CountryStats {
    @extend %stats-text;
  }
}

.TelecomName {
  @extend %base-text;
  font-size: 17px;
}


.TelecomHeader {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 700;
  font-size: 20px;

}


.PricingHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-Title {
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    color: $cieloDarkGray;
    margin: 0px;
    text-align: center;
  }
}

.TabsNSearch {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 16px 0px;
  align-items: center;
  gap: 16px;
  min-height: 40px;



  .SearchNButtonsFields {
    display: flex;
    flex-direction: row;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;

    .PricingRulesButtons,
    .TableButtonOptions {
      display: flex;
      gap: 15px;
      flex-direction: row;
    }
  }
}

.SearchContainer {
  position: relative;

  .SearchInputFullWidth {
    height: 48px;
    border: 1px solid $cielo-border-color;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    padding: 7px 16px 7px 52px;
    outline: none;

    &::placeholder {
      opacity: 0.7;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      opacity: 0.7;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      opacity: 0.7;
    }

    &::-webkit-search-cancel-button {
      position: relative;
      right: 0px;
    }
  }

  .SearchInput {
    height: 48px;
    border: 1px solid $cielo-border-color;
    box-sizing: border-box;
    border-radius: 8px;
    max-width: 260px;
    padding: 7px 16px 7px 52px;
    outline: none;

    &::placeholder {
      opacity: 0.7;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      opacity: 0.7;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      opacity: 0.7;
    }

    &::-webkit-search-cancel-button {
      position: relative;
      right: 0px;
    }
  }

  svg {
    position: absolute;
    left: 16px;
    top: 14px;
    fill: #8f8f8f;
  }
}

.CieloTable {
  &-SortIndicator {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-left: 10px;
    position: relative;
    fill: $cieloNewPink;

    &__NoSort {
      flex-direction: column;
      flex-wrap: nowrap;
      fill: #cccccc;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2px;
      position: relative;
    }
  }

  &-HeaderButton {
    display: flex;
    align-items: center;
    background-color: transparent;

    &__DisableSort:hover {
      cursor: default;
    }
  }

  &-Dropdown {
    margin: 0px;
    padding: 0px;
    list-style: none;

    &.last-item .dropdown-menu {
      transform: translate3d(-30px, -50px, 0px) !important;
    }

    .dropdown-menu {
      transform: translate3d(-30px, 30px, 0px) !important;

      li {
        margin: 0 auto;

        button {
          display: block;
          width: 100%;
          padding: 0.25rem 1rem;
          clear: both;
          font-weight: 600;
          color: var(--cui-dropdown-link-color, #4f5d73);
          text-align: inherit;
          text-decoration: none;
          white-space: nowrap;
          background-color: transparent;
          border: 0;
        }

        a,
        button {
          font-weight: 600;
        }

        &:hover {
          a,
          button {
            background-color: $cieloLightGray;
            border-radius: 8px;
          }
        }

        // &:last-child {
        //   a,
        //   button {
        //     color: #931010;
        //   }
        // }
      }
    }

    &__Button {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      fill: $cieloSecondaryBlue;
      margin: 0 auto;

      &:hover {
        background-color: rgba($color: $cieloNewGreen, $alpha: 0.4);
      }
    }
  }

  thead {
    tr {
      th {
        background-color: $cieloLightGray;
        padding: 16px 10px;
        white-space: nowrap;
        text-align: left;
        color: #8f8f8f;
        font-weight: 700;
        letter-spacing: -0.3px;

        &:first-child {
          border-radius: 8px 0px 0px 0px;
        }

        &:last-child {
          border-radius: 0px 8px 0px 0px;
        }
      }
    }
  }

  tbody {
    tr {
      background-color: $cieloWhite;

      &:hover {
        background-color: #ddd;
      }

      td {
        padding: 20px 15px;
        vertical-align: middle;
        text-align: left;
        font-weight: 500;
        color: #767676;
        letter-spacing: -0.3px;
        line-height: 140%;
      }

      &:last-child {
        td {
          &:first-child {
            border-radius: 0px 0px 0px 8px;
          }

          &:last-child {
            border-radius: 0px 0px 8px 0px;
          }
        }
      }
    }
  }

  thead,
  tbody {
    tr {
      th,
      td {
        &:first-child {
          padding-left: 32px;
          text-align: left;
        }
      }
    }
  }

  &.table > :not(:first-child) {
    border-top: none;
  }
}

.FooterTable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

  &-RecordNumber {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  &-Pagination {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #545454;

    &__Input {
      height: 42px;
      width: 64px;
      border-radius: $cieloUnit/2;
      border: 1px solid $cieloGray;
      text-align: center;
      outline: none;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    .ci-GroupInput {
      margin-bottom: 0 !important;
      input {
        width: 64px !important;
        background: #fff;
      }
    }

    &__Buttons {
      background: transparent;
      border: none;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      outline: none;
      fill: #000000;

      &:disabled {
        svg {
          fill: $cieloGray;
        }
      }
    }
  }
}

.LoadingTable {
  display: flex;
  justify-content: center;
  min-height: 500px;
  align-items: center;
}

@media (min-width: 768px) {
  .PricingHeader {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-Title {
      text-align: left;
    }

    &-ButtonGroup {
      .CieloButton {
        height: 40px;
        padding: 10px 16px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .TabsNSearch {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 32px;
    align-items: center;
    gap: 0;
    flex-direction: row;
  }
}

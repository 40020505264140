.CieloLogo {
  margin-bottom: 15px;
}

.CustomCard {
  border-radius: 16px;
  box-shadow: 0px 7px 20px rgb(0 0 0 / 25%);
  padding: 0px;
}

.Custom-Widget {
  border: none;
  padding-bottom: 1rem;
}

.CardSpinner-Container {
  display: flex;
  justify-content: center;
}
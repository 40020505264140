.button:hover {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  background-color: white;

  svg {
    fill: #8f8f8f;
  }
}

.search-container {
  position: absolute;
  display: inline-block;
  margin: 4px 2px;
  height: 48px;
  width: 50px;
  vertical-align: bottom;
  top: 26px;
  left: 0;
  z-index: 50;
}

.searchbutton {
  position: absolute;
  font-size: 22px;
  width: 100%;
  margin: 0;
  padding: 0;
  top: 0px;
  left: 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #000;
  border-radius: 4px;

  svg {
    fill: #fff;
  }
}

.search:focus + .searchbutton {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  background-color: white;

  border-radius: 4px 0px 0px 4px;

  svg {
    fill: #8f8f8f;
  }
}

.search {
  position: absolute;
  left: 49px;
  /* Button width-1px (Not 50px/100% because that will sometimes show a 1px line between the search box and button) */
  background-color: white;
  outline: none;
  border: none;
  padding: 0;
  width: 0;
  height: 100%;
  z-index: 10;
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;

  &.active {
    width: 185px;
    /* Bar width+1px */
    border-radius: 0px 4px 4px 0px;
    padding: 0 16px;
  }
}

.buttonsNextPrev {
  position: absolute;
  left: 100%;
  margin-left: 195px;
  display: flex;
  button {
    font-size: 22px;
    width: 50px;
    height: 40px;
    margin: 0;
    padding: 0;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: #71f4af;
    border-radius: 4px;
    margin-left: 10px;

    svg {
      fill: #fff;
    }
  }
}

.react-flow__node-default.coincidence {
  border-color: rgba($cieloNewGreen, 1) !important;
}

.LoginPage {
  height: 100vh;
  display: flex;
  width: 100%;
  background: url("(../../../images/png/background-gradient-main.png");
  margin: 0 auto;
  .inner{
    max-width: 1440px;
    height: 100vh;
    display: flex;
    width: 100%;
    margin: 0 auto;
  }
  section {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    padding: 0 50px;

    h1 {
      max-width: 470px;
      font-size: 30px;
      font-weight: bold;
      line-height: 36px;
      margin-top: 40px;
      width: 100%;
      color: #fff;
      span {
        color: $cielo-color-ppal;
      }
    }
  }
  .card-group {
    width: 100%;
    max-width: 580px;
  }
  .CustomCard {
    position: relative;
  }

  .LoginForm {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    &__Title {
      font-weight: bold;
      font-size: 40px;
      line-height: 50px;
      margin: 0px;
      text-align: center;
      margin-bottom: 50px;
      color: #14142b;
    }

    &__SubTitle {
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 24px;
      color: #4e4b66;
    }

    &__ReSend {
      font-size: 20px;  
      color: #50506C;
      
    }

    &-Group {
      margin-bottom: 24px;

      &__LoginLabel {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin: 4px 0px;
      }

      &__LoginInput {
        background: #f7f7f8;
        border: 1px solid #e9e9ed;
        box-sizing: border-box;
        border-radius: 8px;
        margin: 4px 0px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        &::-ms-reveal {
          display: none !important;
        }

        &:focus {
          box-shadow: 0 0 0 0.25rem $cieloSkyBlueRGBA;
          border-color: $cieloSkyBlueRGBA;
        }

        &::placeholder {
          opacity: 0.7;
          /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          opacity: 0.7;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          opacity: 0.7;
        }
      }

      &__ForgotPassword-Link {
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: #4e4b66;
        text-decoration: none;
      }

      &__PasswordGroup {
        position: relative;

        &-eyeLink {
          position: absolute;
          right: 25px;
          top: 6px;
          z-index: 20;
          background-color: transparent;
          border: none;
        }
      }

      &.Captcha-Container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      &__SignIn-Button {
        width: 100%;
      }

      &__NoAccountYet {
        margin: 0px;
        font-weight: 600;
        font-size: 1.75 * $cieloUnit;
        line-height: 24px;

        &-Span {
          color: #4e4b66;
        }

        &-Link {
          color: $cieloNewPink;
          text-decoration: none;
        }
      }

      &:last-child {
        margin: 0px;
      }
    }
    .alert {
      position: absolute;
      z-index: 10;
      bottom: 20px;
      left: 10%;
      width: 80%;
    }
    .ci-button {
      font-size: 18px;
      color: #f7f7f7 !important;
    }
  }
}

@media (min-width: 992px) {
  .LoginPage {
    .CustomCard {
      padding: 18px 42px;

      .LoginForm {
        &-Group {
          &__NoAccountYet {
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media (min-width: 1600px) {
  .LoginPage {
    .CustomCard {
      padding: 52px;
    }
  }
}

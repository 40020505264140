@mixin product-badge() {
  border-radius: 4px;
  color: #36434e;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  padding: 0px 8px;
}

.BadgeContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;

  .FastLane-Badge {
    @include product-badge();
    background-color: #fff6ed;
    font-size: 8px;
  }
  
  .PayPhone-Badge {
    @include product-badge();
    background: #f4edff;
    font-size: 8px;
  }
  .Vipp-Badge {
    @include product-badge();
    background: #ff9db9;
    font-size: 8px;
  }

  .Vnta-Badge {
    @include product-badge();
    background: #800080;
    color: #fff;
    font-size: 8px;
  }
}

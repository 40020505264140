.NodeRuleSelection {
  display: flex;
  flex-direction: row;
  gap: 12px;

  &-Select {
    width: 100%;

    &-Spinner {
      padding: 0px 0px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }
  }

  &-Button {
    width: 60px;
  }
}

.ModalCreateNode{
  .NewNodeInput{
    display: flex;
    button{
      margin-left: 10px;
    }
   
  }
}
@import './FilterButtons';

@mixin default-button {
  padding: 0.375rem 20px;
  border-radius: 4px;
  font-weight: 600;
  color: $cieloWhite;
  display: flex;
  align-items: center;
  gap: 12px;
  flex-direction: row;
  justify-content: center;

  .spinner-border-sm {
    min-width: 1rem;
  }
}

.FullButton {
  @include default-button;
  background-color: $cieloNewPink;
  border-color: $cieloNewPink;
  color: $cieloWhite;
  min-height: 40px;

  &:not(.SVGButton) {
    min-width: 100px;
  }

  svg {
    fill: $cieloWhite;
  }

  &:hover,
  &:active:focus {
    border-color: $cieloNewPink;
    background-color: $cieloSkyBlueRGBA;
    outline: $cieloSkyBlueRGBA;
    color: $cieloWhite;

    svg {
      fill: $cieloWhite;
    }
  }

  &:focus,
  &:active {
    background-color: $cieloNewPink;
    border-color: $cieloNewPink;
    color: $cieloWhite;
  }

  &:disabled {
    background-color: $cieloWhite;
    border-color: $cieloNewPink;
    color: $cieloNewPink;

    svg {
      fill: $cieloNewPink;
    }
  }

  &.swal2-styled.swal2-confirm {
    background-color: $cieloNewPink;
    border-color: $cieloNewPink;
    color: $cieloWhite;

    &:focus {
      box-shadow: none;
    }
  }
}
.btn{
  max-height: 48px;
  border-radius: 8px
}
.GhostButton {
  @include default-button;
  background-color: $cieloWhite;
  height: 40px;
  border: 1px solid $cieloNewPink;
  color: $cieloNewPink;

  &:not(.SVGButton) {
    min-width: 100px;
  }

  svg {
    fill: $cieloNewPink;
  }

  &:focus,
  &:hover,
  &:active,
  &:active:focus {
    border-color: $cieloNewPink;
    background-color: $cieloSkyBlueRGBA;
    outline: $cieloNewPink;
    color: $cieloWhite;

    svg {
      fill: $cieloWhite;
    }
  }

  &.swal2-deny.swal2-styled {
    background-color: $cieloWhite;
    border: 1px solid $cieloNewPink;
    color: $cieloNewPink;

    &:focus {
      box-shadow: none;
    }
  }

  &__DisabledBorder:disabled {
    border: 1px solid $cieloGray;
  }
}

button.text-red-cus {
  color: #931010 !important;
}
button.text-blue-cus {
  color: $cieloNewPink !important;
}

@import './SecurityInput';
@import './TagInput';
@import './AutoComplete';
@import './NodeSearch';

input,
select {
  &:focus {
    // border-color: var(--rs-input-focus-border) !important;
    box-shadow: none !important;
    outline: 0 !important;
  }
}

.AdvancedSettings__Input {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

#ReportsDash {
  .rs-picker-default .rs-picker-toggle {
    border: 1px solid #8f8f8f;
    height: 48px;
  }
  .rs-picker-toggle-textbox {
    height: 46px;
  }
}


$cielo-primary-font: 'Poppins', sans-serif;
$cieloBlack: #000000;
$cieloWhite: #ffffff;
$cieloLightGray: #eff0f6;
$cieloGray: #909090;
$cieloDarkGray: #4b4b4b;

$cieloNewPink: #50506C;
$cieloSecondaryBlue: #0342a0;
$cieloNewGreen: #91F4BF;
$cieloFourthiaryBlue: #000;
$cieloFifthBlue: #1a1a1a;
$cieloDanger: #e9356e;
$cieloBlueRGBA: rgba(0, 97, 247, 0.8);
$cieloSkyBlueRGBA: rgba(134, 183, 254, 1);
$cieloUnit: 8px;

// changes

$cielo-color-ppal: #ff5c8b;
$cielo-color-ppal-dark: #CE4A70;
$cielo-color-bg: #000;
$cielo-text-secondary: #A7ACC1;
$cielo-text-extra: #1E2028;

$cielo-border-color:#ccd0de;


// new 

$cielo-nb-jet:#1F1F29;
$cielo-nb-cloud:#F6F3EF;
$cielo-nb-green:#91F4BF;
$cielo-nb-dusk:#50506C;
$cielo-nb-sky:#CED5DF;
.inputTagContainer {
  .ReactTags__selected {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: fit-content;
    min-height: 38px;
    width: 100%;
    background: white;
    align-items: center;
    padding: 3px 5px;
    gap: 0px 5px;
    justify-content: stretch;
    border: 1px solid var(--cui-input-border-color, #b1b7c1);
    border-radius: 0.25rem;

    .ReactTags__tag {
      background: #efefef;
      padding-left: 6px;
      margin: 2px 0;
      border-radius: 6px;

      .ReactTags__remove {
        border-radius: 6px;

        &:focus-visible {
          outline: none;
        }
      }
    }

    .ReactTags__tagInput {
      width: 50px;
      flex-grow: 1;

      .ReactTags__tagInputField {
        width: 100%;
        border: none;

        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}

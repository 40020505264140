#WrapPay {
  height: 100vh;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  .Content {
    width: 100%;
    max-height: 100%;
    padding: 25px 0;
    overflow-y: auto;
    .card-group{
      max-width: 500px;
      display: flex;
      margin: 0 auto;
      flex-direction:column;
    }
    .CieloLogo {
      display: block;
      margin-bottom: 20px;
    }
    .StripeLogo {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 10px;
    }
    .card {
      .card-header {
        background: none;
        border-bottom: none;
        h1 {
          margin-bottom: 20px;
          text-align: center;
          font-weight: 400;
          font-size: 16px;
        }
        h3 {
          text-align: center;
          margin-bottom: 20px;
          font-size: 26px;
        }
        .product {
          display: flex;
          justify-content: space-between;
          border-bottom: dashed 1px #8a8a8a;
          padding: 10px 0;
          p {
            font-size: 12px;
            color: #8a8a8a;
            margin-right: 20px;
            margin-bottom: 5px;
          }
          span {
            font-size: 14px;
          }
        }
        .total {
          display: flex;
          justify-content: space-between;
          padding: 10px 0;
          p {
            font-size: 14px;
            margin-right: 20px;
            margin-bottom: 5px;
            font-weight: 600;
          }
          b {
            font-size: 14px;
          }
        }
      }
      .logos {
        display: flex;
        width: 100%;
        margin-top: 20px;
        justify-content: space-between;
        align-items: center;
        .logo {
          display: flex;
          align-items: center;
          span {
            font-size: 12px;
            display: block;
            margin-right: 5px;
            white-space: nowrap;
          }
          img {
            display: block;
            margin-bottom: 0;
            height: 15px;
            width: auto;
          }
        }
      }
      padding: 30px;
      border-radius: 16px;
      box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.25);
    }

    .links{
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      a{
        color:#8a8a8a;
        text-decoration: none;
        margin-right: 20px;
        font-size: 12px;
      }
    }

    .StripeElement--webkit-autofill {
      background: transparent !important;
    }

    .StripeElement {
      width: 100%;
      // background: #f7f7f8;
      // border: 1px solid #e9e9ed;
      border-radius: 8px;
      margin-top: 0px;
      margin-bottom: 40px;
      // padding: 11px 15px 11px 15px;
    }
  }
  #payment-message {
    color: $cieloDanger;
    display: block;
    margin-top: 10px;
    font-size: 12px;
  }
}


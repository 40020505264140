@import './NodeForm';

.AccessNumber {
  &-List {
    background-color: white;
    padding: 30px 16px;
    height: 100vh;
    height: calc(100vh - 85px);
    display: flex;
    flex-direction: column;
    gap: 18px;

    &__FilterNButton {
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: space-between;
    }

    &__Container {
      .InfiniteScroll-Container {
        height: calc(100vh - 190px);
        overflow: auto;
        width: 100%;
      }

      h5 {
        text-align: center;
        margin-top: 50px;
      }

      .Spinner {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 40px;
      }
    }

    &__Title {
      font-weight: 700;
      font-size: 24px;
      line-height: 40px;
      text-align: center;
      margin: 0px;
    }

    &__SearchInput {
      width: 100%;

      input {
        height: 48px;
        border: 1px solid $cielo-border-color;
        box-sizing: border-box;
        border-radius: 8px;
        width: 100%;
        padding: 7px 16px 7px 52px;
        outline: none;
      }

      svg {
        position: absolute;
        left: 16px;
        top: 14px;
        fill: #8f8f8f;
      }
    }

    &__Elements {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0;

      svg {
        display: none;
        fill: #0342a0;
      }

      a.nav-link {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        display: block;
        width: 100%;
        padding: 30px 12px;
        text-decoration: none;
        pointer-events: all !important;
        position: relative;

        &.disabled {
          color: var(--cui-nav-link-disabled-color, #8a93a2);
          cursor: default;
        }
        .badge {
          background-color: $cieloDanger;
          position: absolute;
          bottom: 8px;
          left: 10px;
        }
      }

      &.active {
        background: rgba($color: $cieloNewPink, $alpha: 0.6);

        &:hover {
          background: rgba($color: $cieloNewPink, $alpha: 0.6);
        }
      }

      &:hover {
        background: rgba($color: $cieloNewGreen, $alpha: 0.6);
      }

      &:hover {
        svg {
          display: flex;
        }
      }

      .DropDown-Options {
        list-style: none;
        padding: 0;

        .dropdown.show {
          .dropdown-menu {
            transform: translate(-110px, 40px) !important;

            li {
              width: 80%;
              margin: 0 auto;

              button {
                display: block;
                width: 100%;
                padding: 0.25rem 1rem;
                clear: both;
                font-weight: 600;
                color: var(--cui-dropdown-link-color, #4f5d73);
                text-align: inherit;
                text-decoration: none;
                white-space: nowrap;
                background-color: transparent;
                border: 0;
              }

              &:hover {
                background-color: #eff0f6;
                border-radius: 8px;
              }

            }
          }
        }
      }
    }
  }

  &-Iframe {
    height: 100vh;
    height: calc(100vh - 85px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    h1{
      font-size: 24px;
    }
    .react-flow {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.bg-pattern {
  background-image: url(../../../images/png/pattern.png);
  background-repeat: repeat;
}

.react-flow__background {
  background: rgba(var(--cui-light-rgb)) !important;
}

.CallTree-Intructions {
  display: flex;
  justify-content: space-between;
}

.react-flow__attribution.right{
  display: none !important;
}

@media (min-width: 1200px) {
  .AccessNumber {
    &-Iframe {
      & > h1 {
        margin: 0px 65px;
      }
    }
  }
}

.alert-danger{
  border-color: #F6649D !important;
  background: #FFF6FA !important;
  color: #AB0F3E !important;
  .btn-close{
    color: #F6649D !important;
    fill: #F6649D !important;
    stroke: #F6649D !important;
  }
}
// Helpers
.rs-picker-toggle-value{
  margin-top: -4px;
  color: #000!important;
}
.DashboardHeaderBar {
  margin-bottom: 0px;
  padding: 20px 0px;
  height: 85px;
  box-shadow: 0px 4px 4px rgba(171, 171, 171, 0.25);

  &-Title {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

    .breadcrumb-item {
      &.active {
        color: $cieloBlack;
      }

      a {
        color: $cieloBlack;
        text-decoration: none;
      }
    }
  }

  .nav-link {
    text-decoration: none;
  }

  #wrapNotifications {
    .NotificationIcon {
      background: $cieloLightGray;
      border-radius: 50%;
      width: 44px;
      height: 44px;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;

      img {
        width: 28px;
      }

      &-Dot {
        top: 7px;
        left: 23px;
        padding: 4px;
        background: $cieloDanger;
        border: 1px solid white;
      }
    }
    h3 {
      font-size: 14px;
      padding: 5px 10px;
      white-space: nowrap;
    }
    .dropdown-menu {
      padding: 0 !important;
      .ItemNotification {
        padding: 10px 10px;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        color: inherit !important;
        &:hover {
          background: #ddd;
        }
        cursor: pointer;
        span {
          white-space: nowrap;
          display: block;
          &:first-child {
            margin-right: 30px;
          }
        }
      }
    }
  }

  .AvatarToggle {
    display: flex;
    align-items: center;

    .avatar {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .UserInitials {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $cieloFourthiaryBlue;
      border-radius: 50%;
      font-weight: 700;
      letter-spacing: 2px;
      color: $cieloNewGreen;
    }

    &-Information {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-left: 10px;

      &__User,
      &__Role {
        color: $cieloBlack;
      }

      &__User {
        font-size: 2 * $cieloUnit;
        line-height: 3 * $cieloUnit;
        font-weight: 600;
      }

      &__Role {
        opacity: 0.5;
        font-size: 1.75 * $cieloUnit;
        line-height: 21px;
        font-weight: 500;
      }
    }

    &-Arrow {
      margin-left: 16px;
    }
  }
}

@media (min-width: 992px) {
  .DashboardHeaderBar {
    padding: 20px 20px;
  }
}

.FixedBadge,
.DynamicBadge,
.BaseFareBadge,
.AncillaryBadge,
.ActiveStatus,
.PendingStatus,
.ActiveUser,
.DisabledUser,
.DisabledStatus {
  width: 110px;
  height: 28px;
  padding: 2px 12px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
}

.BaseFareBadge {
  color: #98996f;
  background: #feffce;
}

.AncillaryBadge {
  color: #6ab777;
  background: #c0f3c4;
}

.FixedBadge {
  background: #cbddf8;
  color: #4780d8;
}

.DynamicBadge {
  background: #e8f8ff;
  color: #6aa0b7;
}

.PendingStatus {
  background-color: #fcf3e1;
  color: #f9b115;
}

.ActiveUser,
.ActiveStatus {
  background-color: #cff2d8;
  color: #109434;
}

.DisabledUser,
.DisabledStatus {
  background-color: #f2cfcf;
  color: #931010;
}

@import './badges/index';

.NodeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  &-Name {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: start;
    width: 100%;

    p {
      color: #000000;
      font-family: 'Poppins';
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      margin: 0px;
      width: 100%;
      word-break: break-word;
      text-align: left !important;
    }
  }

  &-Divider {
    background-color: #a8a8a8;
    height: 1px;
    margin: 0px;
    width: 100%;
  }

  &-Position {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
    padding-top: 5px;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 8px;
      line-height: 14px;
      color: #9e9e9e;
    }
    .ButtonMenuNode {
      width: 40px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: left;
      fill: #0342a0;
      cursor: pointer;
      background: none;
      svg {
        pointer-events: none;
      }
    }
  }
  &-Menu-Wrap {
    background: none;
  }
  &-Menu {
    list-style: none;
    padding: 0 10px;
    margin-bottom: 0;
    position: relative;
    &-Overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 99999;
    }
    li {
      z-index: 999999;
      width: 100%;
      margin: 0 auto;

      button {
        display: block;
        width: 100%;
        padding: 0.25rem 1rem;
        clear: both;
        font-weight: 600;
        color: var(--cui-dropdown-link-color, #4f5d73);
        text-align: left;
        text-decoration: none;
        white-space: nowrap;
        background-color: transparent;
        font-size: 14px;
        border: 0;
        &.delete {
          color: #931010;
        }
      }

      &:hover {
        background-color: #eff0f6;
        border-radius: 8px;
      }
    }
  }
}

.react-flow__node.react-flow__node-default.nopan.selectable {
  width: 250px;
}

.react-flow__node-default {
  width: 250px !important;
  cursor: default !important;
  &.selectable{
    cursor: grab !important;
  }
  .NodeContainer {
    &-PositionContainer {
      display: none;
      flex-direction: column;
      width: 100%;
      &.unlock {
        display: none !important;
      }
    }
  }
}

.react-flow__node-default.selected {
  z-index: 999999 !important;
  .NodeContainer {
    &-PositionContainer:not(.unlock) {
      display: flex !important;
    }
  }
}
.react-flow__node-default {
  .NodeContainer {
    &-PositionContainer.firstNode {
      display: flex !important;
    }
  }
}
.WrapOrderNodes {
  margin-bottom: 30px;
  .SortableItem {
    padding: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin: 10px 0;
    background: #fff;
    position: relative;
    z-index: 2;
    color: #000000;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    svg {
      fill: $cieloGray;
      border: solid 1px $cieloLightGray;
      border-radius: 2px;
      margin-right: 10px;
      cursor: move;
    }
    &.isDragging {
      position: relative;
      background: #e7f0ff;
      opacity: 0.8;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      z-index: 5;
    }
  }
}

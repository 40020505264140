.form-check-label {
  color: #ffff;
}

.selected-flag {
  background-color: #ffff !important;
}

.select-phone-input {
  border-radius: 8px !important;
}



@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes ripple {
  0% {
    transform: scale(0);
    opacity: 0.5;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.animate-gradient-x {
  animation: gradient-animation 3s ease infinite;
}

.animate-ripple {
  animation: ripple 0.8s ease-out;
}

.bg-size-200 {
  background-size: 200% 200%;
}

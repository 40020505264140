.InterConnection_ActionButtons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
}

.Voip_Switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;

  .InterConnection-Form__Label {
    margin-bottom: 0px;
  }
}

.InterConnection-Form {
  .form-group .btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 48px;

    &:disabled {
      border-color: $cieloGray;
    }
  }

  &__Switch {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-Label {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0px;
    }
  }

  &__InputGroup {
    .input-group-text {
      background-color: $cieloNewGreen;
      svg {
        fill: $cieloSecondaryBlue;
      }
    }
  }

  &__Label {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }

  &__APILink {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    color: $cieloNewPink;
    text-decoration: none;
    font-weight: 600;

    svg {
      fill: $cieloNewPink;
    }
  }

  &__Warning {
    font-size: small;
    color: $cieloDanger;
  }

  & .TableSecretInput {
    input {
      border: none;
    }
  }

  & .PasswordGroup {
    width: 100%;
    input {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: 0;
      height: 48px;
    }
  }

  &_SaveButton {
    display: flex;
    align-items: flex-end;
    max-width: fit-content;
  }

  &__HeaderInfo {
    font-size: 14px;
    margin: 0 20px 0 0;

    .bold {
      font-weight: 600;
    }
  }

  &__Header-ColRight {
    display: flex;
    justify-content: flex-end;

    .FullButton {
      height: 40px;
    }
  }

  &__Header-ColLeft {
    display: flex;
    align-items: flex-end;
  }

  &__TableSpinner {
    td {
      height: 500px;
      text-align: center !important;
    }
  }

  &__SelectableWrapper {
    min-height: 165px;
  }

  &__SelectableBtn {
    width: 100%;
    text-align: left;
    padding: 3px 15px;
    border-radius: 3px;
    margin-bottom: 5px;
    background-color: transparent;
    transition: all 0.3s;

    &:hover,
    &.Selected {
      background-color: #eff0f6;
    }
  }

  @media (max-width: 1000px) {
    &__Header-ColLeft {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media (max-width: 374px) {
    & > .row {
      flex-direction: column;
    }

    &_SaveButton {
      max-width: 100%;
      margin-top: 1rem;
      .btn {
        width: 100%;
      }
    }

    &__Header-ColRight {
      justify-content: flex-start;
    }
  }
}

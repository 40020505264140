


@import '~@coreui/coreui/scss/coreui';
@import 'bootstrap/dist/css/bootstrap.min.css';

@import './fonts';
@import './variables';
@import './custom';
@import './new-styles';

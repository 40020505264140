.ChartLegends {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  gap: 12px;
  justify-content: center;
  align-items: center;
  font-size: small;

  &-Legend1 {
    position: relative;
    &::before {
      content: '';
      position: relative;
      height: 12px;
      border-radius: 25px;
      width: 12px;
      background-color: $cieloSecondaryBlue;
      display: inline-block;
      margin-right: 5px;
    }
  }

  &-Legend2 {
    position: relative;
    &::before {
      content: '';
      position: relative;
      height: 12px;
      border-radius: 25px;
      width: 12px;
      background-color: $cieloNewGreen;
      display: inline-block;
      margin-right: 5px;
    }
  }
  &-Legend3 {
    position: relative;
    &::before {
      content: '';
      position: relative;
      height: 12px;
      border-radius: 25px;
      width: 12px;
      background-color:$cieloNewPink;
      display: inline-block;
      margin-right: 5px;
    }
  }

  &-Legend4 {
    position: relative;
    &::before {
      content: '';
      position: relative;
      height: 12px;
      border-radius: 25px;
      width: 12px;
      background-color:#800080;
      display: inline-block;
      margin-right: 5px;
    }
  }
}

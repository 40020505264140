.Dashboard-Sidebar {
  --cui-sidebar-width: 100%;
  background-color: $cielo-nb-jet;

  .sidebar-brand {
    padding-right: 3 * $cieloUnit;
    padding-left: 3 * $cieloUnit;
    flex-direction: column;
    margin-bottom: 16px;
    background: transparent;

    .Brand-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      img {
        margin: 28px auto;
      }

      .close-menu {
        color: white;
        font-weight: bold;
      }
    }

    hr {
      width: 225px;
      margin: 0px;
    }
  }
  .sidebar-nav .nav-group.show {
    background: $cieloFifthBlue;

    .nav-group-toggle:hover {
      color: $cieloWhite;
    }
  }

  .sidebar-nav .nav-group-items .nav-link {
    padding-left: 3rem;
  }
  .sidebar-nav {
    li:last-child {
      margin-top: auto;
    }
  }
  .nav-title {
    padding: 8px 25px;
    font-size: 1.75 * $cieloUnit;
    margin: 0px;
    text-transform: none;
    font-weight: 500;
  }

  .nav-link {
    padding: 24px 25px;
    color: white;
    font-size: 2 * $cieloUnit;
    font-weight: 600;
    height: 76px;
    position: relative;
    line-height: 76px;
    &::before {
      content: '';
      width: 4px;
      height: 42px;
      position: absolute;
      left: 0;
      top: 16px;
      border-radius: 0 4px 4px 0;
      background-color: transparent;
    }

    &__Icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin-right: 10px;

      svg {
        fill: none;
      }
    }

    &__NoIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      margin-right: 10px;
      background: transparent !important;
      }

    .nav-link__Icon svg {
      fill: #fff;
    }
    &:hover,
    &.active {
      &::before {
        background-color: #fff;
      }
      color: var(--cui-sidebar-nav-link-hover-color, rgba(255, 255, 255, 1));
      text-decoration: none;
      // background: var(--cui-sidebar-nav-link-hover-bg, rgba(255, 255, 255, 0.25));

      .nav-link__Icon {
        background: #282a36;
        svg {
          fill: #fff;
        }
      }
    }
  }
}
.sidebar:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 340px;
}
.DashboardContent {
  padding-left: var(--cui-sidebar-occupy-start);
}

@media (max-width: 570px) {
  .Dashboard-Sidebar {
    .sidebar-brand {
      .Brand-container {
        img {
          margin: 22px 0px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .Dashboard-Sidebar {
    --cui-sidebar-width: 340px;
  }
  .sidebar-brand {
    padding-right: 9 * $cieloUnit;
    padding-left: 9 * $cieloUnit;
    .Brand-container {
      img {
        margin: 22px auto;
      }
    }
  }
}

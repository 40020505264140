.ExternalPage {
  .CardContainer {
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 295px;
    width: 100%;
    max-width: 512px;
    margin: 0 auto;

    .CustomCard {
      border-radius: 16px;
      box-shadow: 0px 7px 20px rgb(0 0 0 / 25%);
      padding: 0px;
      height: 600px;
    }
  }

  .FooterContactInformation {
    text-align: center;
    color: #71f4af;
    font-weight: 600;
    line-height: 24px;
    margin: 0px;
    height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &__Links {
      color: #71f4af;
      text-decoration: none;
      font-size: 16px;
    }

    &__SpanDot {
      margin: 0px 16px;
      font-size: 8px;
    }
  }
}
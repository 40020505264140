.TabContainer {
  display: flex;
  .FilterButtons {
    height: 40px;
    background: #ffffff;
    box-shadow: inset -1px 0px 0px #f5f5f5;
    margin: 0px 0px;
    padding: 8px 16px;
    overflow: hidden;
    border-radius: 0px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #8F8F8F;

    &:first-child {
      border-radius: 8px 0px 0px 8px;
    }
    &:last-child {
      border-radius: 0px 8px 8px 0px;
    }
    &.ActiveTab {
      position: relative;
      color: $cieloNewPink;
      &::after {
        content: '';
        position: absolute;
        background-color: $cieloNewPink;
        height: 4px;
        width: 100%;
        bottom: 0px;
        left: 0px;
      }
    }
  }
}

.Toast {
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.LoginPage{
  .Toast {
    align-items: center;
    position: fixed;
    left: auto;
    right: 20px;
    bottom:20px;
    transform: none;
  }
}

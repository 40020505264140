.ToggleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  label{
    font-size: 18px ;
    font-weight: 600;
  }
  &-Swicth {
    display: flex;
    gap: 12px;
    flex-direction: row;
    justify-content: flex-end !important;

    &__Label {
      color: $cielo-text-extra;
      font-size: 15px !important;
      font-weight: 600 !important;
      text-align: right;
      width: 90px; 

      &.InactiveOption {
        // color: $cieloDarkGray;
      }

      &.ActiveOption {
        // color: $cielo-text-extra;
      }
    }
  }
}

.switch + .ToggleHeader-Swicth__Label {
  text-align: left !important;
}

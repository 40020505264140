.CieloModal {
  overflow: hidden;

  .modal-content {
    border: none;
    border-radius: 16px;
  }

  .form-label {
    font-weight: 600;
    margin-bottom: 0;
    color: #000000 !important;
  }

  .alert-link {
    cursor: pointer;
  }

  &-Header {
    background-color: rgba($color: #000000, $alpha: .95);
    margin-bottom: 2 * $cieloUnit;
    padding: 1rem 2rem !important;
    padding-right: 60px;
    position: relative;
    &.modal-header {
      .btn-close {
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.90994 9.00019L16.2099 2.71019C16.3982 2.52188 16.504 2.26649 16.504 2.00019C16.504 1.73388 16.3982 1.47849 16.2099 1.29019C16.0216 1.10188 15.7662 0.996094 15.4999 0.996094C15.2336 0.996094 14.9782 1.10188 14.7899 1.29019L8.49994 7.59019L2.20994 1.29019C2.02164 1.10188 1.76624 0.996094 1.49994 0.996094C1.23364 0.996094 0.978243 1.10188 0.789939 1.29019C0.601635 1.47849 0.495847 1.73388 0.495847 2.00019C0.495847 2.26649 0.601635 2.52188 0.789939 2.71019L7.08994 9.00019L0.789939 15.2902C0.696211 15.3831 0.621816 15.4937 0.571048 15.6156C0.520279 15.7375 0.494141 15.8682 0.494141 16.0002C0.494141 16.1322 0.520279 16.2629 0.571048 16.3848C0.621816 16.5066 0.696211 16.6172 0.789939 16.7102C0.882902 16.8039 0.993503 16.8783 1.11536 16.9291C1.23722 16.9798 1.36793 17.006 1.49994 17.006C1.63195 17.006 1.76266 16.9798 1.88452 16.9291C2.00638 16.8783 2.11698 16.8039 2.20994 16.7102L8.49994 10.4102L14.7899 16.7102C14.8829 16.8039 14.9935 16.8783 15.1154 16.9291C15.2372 16.9798 15.3679 17.006 15.4999 17.006C15.632 17.006 15.7627 16.9798 15.8845 16.9291C16.0064 16.8783 16.117 16.8039 16.2099 16.7102C16.3037 16.6172 16.3781 16.5066 16.4288 16.3848C16.4796 16.2629 16.5057 16.1322 16.5057 16.0002C16.5057 15.8682 16.4796 15.7375 16.4288 15.6156C16.3781 15.4937 16.3037 15.3831 16.2099 15.2902L9.90994 9.00019Z' fill='%23ffffff'/%3E%3C/svg%3E");
      }
    }

    &__Title {
      color: $cieloWhite;
      font-size: 24px;
      font-weight: 700;
      display: flex;
      flex: 1;
    }
    button{
      background: none;
      position: absolute; 
      right: 10px;
      top: 18px;
      svg{
        fill: #fff !important;
      }
    }
  }

  &-Body {
    overflow-y: scroll;
    max-height: 65vh;
    padding: 1rem 2rem;

    &__Panel {
      padding: 2rem;
    }
  }
  &-Footer{
    flex-wrap: nowrap !important;
    &-Buttons{
      display: flex;
      button{
        min-width: 162px !important;
        margin-left: 10px;
        border-radius: 12px !important;
        height: 48px !important;
        line-height: 36px !important;
        font-size: 15px !important;
        font-weight: 600 !important;
      }
    }
  }
}

.GeneralModal-Body {
  overflow-y: auto;
  max-height: 400px;
  overflow-x: hidden;
  padding: 1rem 2rem;
}

.Modal-NavigationTabs {
  padding: 0rem 2rem;
  margin: 0rem -2rem 2rem;
  .nav{
    border-bottom: solid 1px rgba(#000,.1);
  }

  .nav-link {
    color: $cielo-text-secondary;
    font-weight: 700;
    font-size: 15px;
    text-decoration: none;
    
    &.active {
      color: $cielo-text-extra;
      border-bottom: 2px solid $cielo-text-extra;
    }
  }
}

.CancelWarning {
  font-size: 0.8rem;
  text-align: right;
  margin-right: 10px;
  color:$cieloDanger
}

.VoIP-Modal {
  .GeneralModal-Body {
    max-height: 65vh;
    min-height: 300px;
  }
}

.tfaInputs-Group {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 0px 16px;

  .tfaInputs-Container {
    position: relative;

    &:focus-within:after {
      content: '';
      background: $cieloNewPink;
      height: 1px;
      width: 20px;
      display: block;
      bottom: 8px;
      top: auto;
      left: auto;
      position: absolute;
      right: 12px;
      animation: blink-animation 1s steps(5, start) infinite;
      -webkit-animation: blink-animation 1s steps(5, start) infinite;
    }
  }


  .tfaInputs {
    width: 44px;
    height: 44px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    background: #f5f5f5;
    caret-color: transparent;
  }
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}


.fullscreen-bg {
  position: fixed !important;
  width: 100% !important;
  height: 100vh !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9998 !important;
  display: block !important;
  overflow-x: hidden !important;
  background: url("../../../images/png/Cielo-Blur-Dark.png") no-repeat center center;
  background-color: #1F1F29 !important;
  background-size: cover;
}


.fullscreen-bg-white {
  position: fixed !important;
  width: 100% !important;
  height: 100vh !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9998 !important;
  display: block !important;
  overflow-x: hidden !important;
  background: url("../../../images/png/cielo-bg-light.png") no-repeat center center;
  background-color: #FFFFFF !important;
  background-size: cover;
}

.vip-outbound-container {
  display: flex;
  justify-content: center;
  margin-top: 2vh;
  width: 100%;
}

.form-container{
  height: 400px;
}

.vip-outbound-title {
  font-family: "Financier Display";
  font-size: 40px;
  line-height: 43.4px;
  margin: 0;
  text-align: center;
  color: #ffffff;
}

.swal2-container {
  z-index: 10000 !important;
}

.vip-outbound-subtitle {
  margin-top: 5px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #fbd3b6;
}

.text-ventana {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;

}

.vipo-input {
  background-color: #ffffff;
  width: 350px !important;
  height: 46px !important;
}

.row .vipo-expanded-input {
  width: 100% !important;
}


.ventana-input {
  background-color: #ffffff;
  height: 48px !important;
}

.ventana-outbound-container {
  justify-content: start;
  margin-top: 5vh;
  width: 100%;
}


.react-select-custom-ventana {
  margin-bottom: 24px;
}

.table-rows {
  height: 50px;

th span, td span {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  margin-left: 16px;
  color: #1E2028;
}



td {
  font-weight: normal!important; 
}


}

tr:nth-child(odd) {
  background-color: #EDEEF3; 
}

tr:nth-child(even) {
  background-color: #FFFFFF; 
}



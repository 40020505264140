.PricingForm {
  &__RuleName {
    margin-bottom: 2rem;
  }
  &__RuleName + .Modal-NavigationTabs + .tab-content {
    .InactiveOption:not(.not-green) + .switch{
      .slider.round{
        background-color: $cieloNewGreen;
      }
    }
  }
  
  &-EditRule {
    &__Information {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      color: $cieloSecondaryBlue;

      p {
        margin: 0px;

        span {
          font-weight: 600;
        }
      }
    }
  }

  &__Label {
    font-size: 18px;
    font-weight: 400 !important;
    color: #000 !important;
    margin-bottom: 5px !important;
  }

  &__Input {
    padding: 3px 16px;
  }

  &-Settings {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-bottom: 0 !important;

    &__ButtonGroup {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 38px;

      button {
        padding: 5px;
        background: transparent;
        &:first-child{
          margin-right: 10px;
        }
      }
    }

    &_SndCol {
      flex: 1 0;
    }

    &_PriceCol {
      width: 145px;
      flex: initial;
    }
  }

  &-PricingMethod {
    padding: 0px;
    border-radius: $cieloUnit;
    margin-top: 20px;
  }

  &-Accordion {
    &__Item {
      border: none;

      &-Header {
        button {
          background: #f5f5f5 !important;
          color: $cieloSecondaryBlue !important;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 1.5px;

          &:focus {
            -webkit-box-shadow: none;
            box-shadow: none;
          }
        }
      }

      &-Body {
        padding: 1rem 0rem;
      }
    }
  }

  &-AccordionCard {
    border: none;
  }

  &-AccordionContent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 16px;

    &.Accordion-Child {
      border: 1px solid rgba($color: #000000, $alpha: .1);
      border-radius: $cieloUnit;
    }
  }
}

.Accordion-GrandChild {
  border: none;
}

.rs-anim-fade.rs-anim-in.placement-bottom-start {
  z-index: 9999999999;
  width: 100%;
  max-width: 277px;
  max-height: 250px;
}

.CustomPricingTag {
  background: transparent;
  width: 100%;

  .react-tag-input__tag__content {
    &:before {
      content: '$ ';
    }
  }

  .react-tag-input__tag__remove {
    background-color: transparent;
  }
}

@media (min-width: 768px) {
  .PricingForm {
    &-EditRule {
      &__Information {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}

@media (max-width: 768px) {
  .PricingForm {
    &-Settings {
      &_SndCol {
        flex: 0 0 auto;
        width: 33.33333333%;
      }
      &_PriceCol {
        width: 28%;
        flex: 0 0 auto;
      }
    }
  }
}


.billing-ventana{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .content-radio{
    display: flex;
    label{
      position: relative;
      height: 48px;
      padding: 0 15px;
      font-weight: 700;
      font-size: 15px;
      color: #1E2028;
      line-height: 48px;
      display: block;
      border-radius: 12px;
      cursor: pointer;
      text-align: center;
      &.active{
        background: #ff5c8b;
        color: #fff;
      }
      input{
        position: absolute;
        top:99999999%;
        left: 99999999%;
      }
    }
  }
}

.price-per-instace{
  display: flex;
  justify-content: space-between;
}

.modal-ventana-form{
  .PricingForm__Label{
    font-weight:600 !important;
    margin-bottom: 15px !important;
  }
}

.callpassmodal,.vippmodal, .ventanamodal{
  .PricingForm-AccordionContent{
    gap:0 16px !important ;
  }
  .PricingForm-Settings.labels{
    margin-bottom: 16px !important;
  }
}
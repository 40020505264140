@media (max-width: 1200px) {
  .LoginPage {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .CardCenteredContainer,
    .FooterCenteredContainer {
      -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
      transform: scale(0.8);

      .CustomCard {
        padding: 26px;
      }
    }
    section{
      width: 100%;
      max-width: 650px;
    }
    section:first-child{
      margin-bottom: 50px;
    }
  }

  .Dashboard-Sidebar {
    --cui-sidebar-width: 280px;
  }

  .sidebar:not(.sidebar-end)~* {
    --cui-sidebar-occupy-start: 280px;
  }

  .Dashboard-Content {
    .CustomCard {
      .card-body {
        padding: 8px;

        .Custom-Widget {
          padding-bottom: 5px;

          .card-body {
            padding: 5px;
          }
        }
      }
    }
  }

  .CustomCard {
    .Chart-Title {
      font-size: 14px;
    }

    .ChartLegends-Legend1::before,
    .ChartLegends-Legend2::before {
      width: 15px;
    }
  }
}
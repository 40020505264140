.Spinner-Container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.TableSpinner {
  td {
    height: 500px;
    text-align: center !important;
  }
}

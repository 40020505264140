.CustomAccordion {
  &-Item {
    border: none;

    &__Header {
      button {
        background: #f5f5f5 !important;
        color: $cieloSecondaryBlue !important;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1.5px;

        &:focus {
          box-shadow: none;
        }
      }
    }

    &__Body {
      padding: 1rem 0rem;
    }
  }
}


.ci-button {
  height: 54px;
  border-radius: 12px;
  color: #fff;
  justify-content: center;
  display: block;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  white-space: nowrap;
  &.primary {
    background: #50506C;
    &:disabled {
      background: rgba($color: #50506C, $alpha: 0.5);
    }
    &:hover {
      background: #50506C
    }
  }
  &.link {
    background: none;
    color: #000;
    &:disabled {
      background: none;
    }
    &:hover {
      background: none;
    }
  }
  &.secondary-border {
    background: none;
    color: #000 !important;
    border: solid 1px #ccd0de !important;
    &:disabled {
      background: none;
    }
    &:hover {
      background: none;
    }
  }
  &.resend-button-border {
    background: none;
    color: #000 !important;
    border: solid 1px #50506C !important;

    &:disabled {
      background: none;
    }
    &:hover {
      background: none;
    }

    span {
      font-size: 20px;
      color: #50506C;
    }
  }
  svg {
    width: 18px!important;
    height: 18px!important;
  }
}

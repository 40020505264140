.PageNotFound {
  min-height: calc(100vh - 85px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // margin-top: 50px;

    &__Title {
      margin: 0px 1.5rem 0px 0px;
      font-size: 48px;
    }

    &__SubTitle {
      margin: 0px;
      padding: 0px;
    }

    &__Description {
      text-align: center;
    }
  }
}
.PasswordGroup {
  position: relative;

  input {
    padding-right: 50px;
  }

  &-eyeLink {
    position: absolute;
    right: 12px;
    top: 6px;
    background-color: transparent;
    border: none;
  }
}

@import './pages/Layout/index';
@import './pages/Demo/demo.scss';
@import './pages/Login/index';
@import './pages/Buy/index';
@import './base/buttons/index';
@import './base/divider/index';
@import './base/cards/index';
@import './base/toggle/index';
@import './base/modals/index';
@import './base/switch/index';
@import './base/collapse/index';
@import './base/inputs/index';
@import './base/nodes/index';
@import './base/legend/index';
@import './base/tables/index';
@import './base/toasts/index';
@import './base/spinner/index';
@import './base/badges/index';
@import './base/tagPicker/index';
@import './pages/Dashboard/index';
@import './pages/PaymentSuccess/index';
@import './pages/PricingRules/index';
@import './pages/CallFlow/index';
@import './pages/InterConnection/index';
@import './pages/TwoFactor/index';
@import './pages/Roles/index';
@import './pages/customZoom';
@import './pages/TwilioTest/index';
@import 'node_modules/@pathofdev/react-tag-input/src/styles/index.scss';
@import 'rsuite/dist/rsuite.min.css'; // or 'rsuite/dist/rsuite.min.css'

body {
  font-family: $cielo-primary-font;
  font-style: normal;
  color: $cieloBlack;
  background-color: #fbfbff;

  .Dashboard-Content {
    padding: 32px 15px;
    background-color: #fbfbff;
  }

  &.modal-open {
    div#root {
      position: fixed;
      width: 100%;
    }
  }
}

.Cielo-bg {
  position: relative;
  overflow: hidden;

  // &-blur {
  //   background: linear-gradient(241.75deg, #20cefd 21.39%, #4460d9 93.16%);
  //   height: 200px;
  //   position: absolute;
  //   width: 110%;
  //   top: 42%;
  //   filter: blur(150px);
  // }
}

.bg-cielo-nb-jet {
  background-color: $cielo-nb-jet;
}

.tooltip-inner {
  text-align: left;
}
::placeholder {
  color: transparent !important;
}
@media (min-width: 768px) {
  body {
    .Dashboard-Content {
      padding: 32px;
    }
  }
}

//new class
.ci-color-ppal {
  color: $cielo-color-ppal;
}
.ci-bg-ppal {
  background: $cielo-color-ppal;
}

.inputTagContainer .ReactTags__selected {
  min-height: 48px !important;
  border-radius: 8px !important;
  border: #ccd0de solid 1px !important;
  position: relative !important;
  margin-bottom: 24px !important;
  padding-left: 10px;
}

.AutoCompleteInput {
  height: 48px !important;
  border-radius: 8px !important;
  border: #ccd0de solid 1px !important;
  position: relative !important;
  margin-bottom: 24px !important;
  overflow: hidden;
  input {
    border: none;
    height: 46px;
    line-height: 46px;
    border-radius: 8px;
  }
}

.rs-picker-input {
  border: #ccd0de solid 1px !important;
  .rs-picker-toggle {
    height: 60px !important;
    line-height: 60px;
  }
  .rs-picker-tag-wrapper {
    height: 60px !important;
    input {
      height: 60px !important;
      line-height: 60px !important;
    }
  }
}

.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  margin-top: 0 !important;
}

.rs-picker-toggle-placeholder {
  margin-top: -5px !important;
  display: block;
}

.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 20px;
}

.PricingRulesButtons .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.PricingRulesButtons .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 14px;
}

.rs-picker-default .rs-picker-toggle {
  height: 48px;
  border-radius: 8px;
  border: #ccd0de solid 1px !important;
  line-height: 42px;
  outline: none !important;
  box-shadow: none !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 14px;
}

.basic-multi-select .select__control {
  height: 48px !important;
  border-radius: 8px !important;
}

.react-select-custom {
  font-size: 15px !important;
  // [class*="singleValue"]{
  //   color: #807F7F !important;
  // }
  > div {
    min-height: 48px;
    border-radius: 8px;
    border-color: #ccd0de !important;
    &:nth-child(3) {
      box-shadow: none !important;
    }
  }
  &label ~ div:first-child {
    > div {
      padding-left: 10px;
    }
  }
  &.multi {
    > div:last-child {
      min-height: 0;
    }
  }
}

#accessKey {
  color: #fff !important;
}

.select-currency {
  input {
    height: 48px;
    border-radius: 8px;
    border: #ccd0de solid 1px;
    position: relative;
    margin-bottom: 24px;
    width: 100%;
    font-size: 15px;
    padding: 0 12px;
  }
  .react-autosuggest__suggestions-container--open {
    right: 0;
    width: 100%;
    min-width: 280px;
  }
  .react-autosuggest__suggestions-list {
    .flag-icon {
      display: none;
    }
  }
}

.CieloTable {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;

  tbody {
    td {
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;
      max-width: 300px;
      overflow-wrap: break-word;

      &.active-row {
        opacity: 1;
      }

      &.deactivated-row {
        opacity: 0.4;
      }
    }
  }

  &.Reports {
    thead {
      tr {
        th {
          text-align: left;

          &:last-child {
            padding-right: 32px;
            text-align: right;
          }
        }
      }
    }

    tbody {
      td {
        padding: 20px 10px;
        text-align: left;
        width: 200px;

        &.active-row {
          opacity: 1;
        }

        &.deactivated-row {
          opacity: 0.4;
        }

        &:last-child {
          text-align: right;
          padding-right: 32px;
        }
      }
    }
  }
}

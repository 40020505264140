.Dashboard-Content {
  &-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  &__Title {
    font-size: 3.2 * $cieloUnit;
    font-weight: bold;
    line-height: 5.625 * $cieloUnit;
    margin: 0;
    padding-left: 15px;

    span {
      color: $cieloNewPink;
    }
  }
  & .Spinner-Container {
    min-height: 500px;
    align-items: center;
  }
}

.Chart-Title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.ChartPie {
  max-width: 80%;
  max-height: 80%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .Dashboard-Content {
    &-Container {
      justify-content: space-between;
      flex-direction: row;
    }

    &__Title {
      font-size: 3.75 * $cieloUnit;
    }
  }
}

@media (max-width: 1440px) {
  .CustomCard {
    .card-body {
      .Chart-Title {
        font-size: 14px;
      }

      .Custom-Widget {
        .card-body {
          padding: 8px;
        }
      }
    }
  }
}


.Wrap-input-icon{
  position: relative;
  svg{
    position: absolute;
    z-index: 999;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    cursor: pointer;
    fill: #909090;
  }
}